.search-dialog-input-container {
  position: relative;
}

.search-dialog-input-container .search-dialog-input {
  width: 100%;
  padding-left: 40px;
  border: none;
  border-radius: 0.25rem;
  font-family: "Halis GR Regular", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  text-transform: uppercase;
}

.search-dialog-input-container .search-dialog-input::placeholder {
  color: #dadada;
}

.search-dialog-input-container .search-dialog-input-submit {
  position: absolute;
  top: 0;
  left: 10px;
  bottom: 0;
}
